
export default function AboutMe() {
    return(
        <section id="AboutMe" className="about--section">
            <div className="about--section--img">
                <img src="./img/about_me2.png" alt="About Me" />
            </div>
            <div className="hero--section--content--box about--section--box">
                <div className="hero--section--content">
                    {/* <p className="section--title">About</p> */}
                    <h1 className="skills-section--heading">About Me</h1>
                    <p className="hero--section-description">
                    Hi, I’m Rehean (I go by Rey)! 👋. I'm a passionate third-year software engineering student at McGill University with a love for problem-solving and innovation. My curiosity drives me to explore how technology can make life easier for both businesses and individuals. Outside of coding, you’ll find me diving into the latest tech trends, experimenting with new cooking recipes, staying active at the gym, or traveling and spending quality time with friends.
                    </p>
                    <p className="hero--section-description">
                    I am currently a Software Engineering Intern at iA Financial Group, working with the Platform Engineering team. Previously, I completed two full-stack software engineering internships at Libro, where I contributed to a SaaS product supporting thousands of restaurants worldwide. In addition to my internships, I have pursued freelance software development, including designing and building a lead management tool for a team of 26 insurance agents at iA Financial Group. This solution translated business needs into technical features, with projected sales growth of 35%. My diverse experiences have equipped me with the skills to develop impactful, results-driven technology solutions.                    </p>
                </div>
            </div>
      </section>
    )
}